import React, { useState, useEffect } from "react"

import { Form, Col, Row } from "react-bootstrap"
import { useLocation } from "@reach/router"
import HTMLReactParser from 'html-react-parser';

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import RadioField from './elements/radio'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import $ from 'jquery'

import axios from "axios"
import * as qs from "query-string"
import Dacha from "../../images/dacha.png"


function StaticContactView(props) {
  const [startDate, setStartDate] = useState();
  const location = useLocation();

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();
  const phone = props?.subject?.phone || ""
  const whatsapp = props?.subject?.whatsapp || "";
  const email_address = props?.subject?.email || "";
  const formatAddress = (address) => {
    const parts = address.split(',').map(part => part.trim());

    if (parts.length > 2) {
      const firstLine = parts.slice(0, 2).join(', ') + ',';
      const secondLine = parts.slice(2).join(', ').trim();
      return `${firstLine}<br />${secondLine}`;
    }

    return parts.map(line => line.trim()).join(', ');
  };



  const formattedAddress = formatAddress(props?.subject?.address);

  const fields = ([
    {
      element: "config",
      formname: "Book Appointment",
      form_type: "contact",
      error_text: "Highlighted fields are required | invalid",
      success_text: "Thank you for contacting Dacha. A member of our team will contact you shortly. ",
      email_temp_user: "book_a_viewing_user",
      email_temp_admin: "book_a_viewing_admin",
      email_server_func: "book_a_viewing",
      event_tracking: "book_a_appointment",
      page_url: "/general-enquiry"
    },

    {
      grpmd: "12",
      label: "Name*",
      placeholder: "Your Name",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      labelClass: "content_b-18"
    },
    {
      grpmd: "12",
      label: "Email Address*",
      placeholder: "Your Email Address",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      labelClass: "content_b-18"

    },
    {
      grpmd: "12",
      label: "Phone Number*",
      placeholder: "Your Phone Number",
      name: "telephone",
      type: "number",
      element: "input",
      required: true,
      patternchk: "^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$",
      labelClass: "content_b-18"

    },
    {
      grpmd: "12",
      label: "Select Enquiry Type",
      placeholder: "Select Enquiry Type",
      name: "enquiry_type",
      id: "enquiry_type",
      event_tracking_decider: "enquiry_type",
      element: "select",
      required: true,
      type: "select",
      step: "stepfive",
      class: "select-box",
      values: ['Viewings', 'Valuations', 'Consultation', 'Property Management', 'Mortgages', 'Other'],
    },
    {
      grpmd: "12",
      label: "Message",
      placeholder: "Your message...",
      name: "message",
      element: "textarea",
      class: "mb-4",
      rows: "2"
    },
    {
      grpmd: "12",
      name: "Send Enquiry",
      type: "submit",
      element: "button",
      value: "submit",
      class: "btn btn-primary modal-btn",
      labelClass: "content_b-18"
    },
    // {
    //   text: 'By clicking Send Enquiry, you agree to our <a href="/terms-and-conditions"> Terms &amp; Conditions </a> and <a href="/privacy-policy"> Privacy Policy </a>',
    //   element: "html",
    //   class: "terms modalterms"
    // },
    {
      element: "captcha",
      class: "py-2 captcha-class",
      captchaRef: recaptchaRef
    },
    // {
    //   name: "negotiatoremail",
    //   type: "hidden",
    //   element: "input",
    //   defaultValue: props.subject?.negotiator?.Email
    // }
  ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  useEffect(() => {
    if (token !== '') {

      const processFromData = async () => {

        let formData = new FormData();

        formvalues['g-recaptcha-response'] = token;

        formvalues['extra'] = JSON.stringify({
          formname: formvalues.formname,
          message: formvalues.message,
          enquiry_type: formvalues.enquiry_type,
          form_type: formvalues.form_type,
          date_time: formvalues.date_time
        });

        formData.append('data', JSON.stringify(formvalues));


        postFormData(formData).then(async apiRes => {


          window.grecaptcha.reset()

          // lets send mail
          formvalues['email_subject_user'] = fields[0].formname;
          formvalues['email_subject_admin'] = fields[0].formname;
          await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
            method: `POST`,
            mode: "no-cors",
            headers: {
              'Access-Control-Allow-Origin': '*',
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(formvalues),
          })
        });


        const axiosOptions_netlify = {
          url: fields[0].page_url,
          method: "post",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: qs.stringify(formvalues),
        }

        // axios(axiosOptions_netlify)
        //   .then(response => {
        //     console.log('data stored')
        //   })
        //   .catch(err =>
        //     console.log(err)
        //   );
        const url = typeof window !== 'undefined' ? window.location.href : ''
        if (url.indexOf("properties") > -1) {
          fields[0].formname = 'Book a Viewing';
        }
        // tracking event
        window.dataLayer = window.dataLayer || [];
        if (props.subject?.isProductDetail) {
          window.dataLayer.push({
            'event': 'formSubmit',
            'formType': formvalues.enquiry_type,
            'formId': 'form-book_a_viewing',
            'formName': `Book a Viewing`,
            'formLabel': `Book a Viewing`,
          });
        } else {
          window.dataLayer.push({
            'event': 'formSubmit',
            'formType': formvalues.enquiry_type,
            'formId': 'form-' + fields[0].event_tracking,
            'formName': fields[0].formname,
            'formLabel': fields[0].formname
          });
        }


        setShowerror(false);
        setThankyou(true);
        setTimeout(() => {
          $(".alert-success").show().delay(4000).fadeOut();
        }, 500)

        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['property_id'] = props.subject.property_id ? props.subject.property_id : '';
      json['property_url'] = props.subject.page_url ? props.subject.page_url : '';
      json['property_title'] = props.subject.property_title ? props.subject.property_title : '';
      json['property_img'] = props.subject.property_img ? props.subject.property_img : '';
      json['property_list'] = props.subject.properties ? props.subject.properties : '';
      json['g-recaptcha-response'] = token;

      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();
      setStartDate()
    }
  };

  var location_link = process.env.GATSBY_SITE_URL + location.pathname;

  var whatsapp_no = props.subject.negotiator_details?.Phone ? props.subject.negotiator_details?.Phone.replace(/ /g, '').replace(/\+/g, '') : "9710505313003"

  var whatsapp_link = `https://wa.me/${whatsapp_no}?text=Hello!%20I%20want%20to%20know%20more%20about%20${location_link}`
  if (location.search.indexOf("utm_source") > 0) {
    whatsapp_link = `https://wa.me/${whatsapp_no}?text=*G*%20Hello!%20I%20want%20to%20know%20more%20about%20${location_link}`
  }

  return (
    <div className="form stbform valuation-main book-a-viewing-form">
      <div ref={myRef} />
      <div className="contact-staticform">

        {showerror && <div className="alert-error">
          <p>{fields[0].error_text}</p>
        </div>}

        {showthankyou && <div className="alert-success">
          <p>{fields[0].success_text}</p>
        </div>}

        <div className="container">
          <Row style={{ marginRight: "0px" }}>
            <Col>
              <div className={`${(props.subject?.isProductDetail) ? "col-md-7" : "col-md-12"}`}>
                <Form className="contact-form modal-form" name={fields[0].formname} method="post" noValidate validated={validated} onSubmit={handleSubmit}>
                  <input type="hidden" name="form_name" value={fields[0].formname} />
                  <input type="hidden" name="form_type" value={fields[0].form_type} />
                  <input type="hidden" name="bot-field" />
                  <div className="form-title">
                    {HTMLReactParser(props.subject.property_title.replace(/\n/g, ''))}
                  </div>

                  {/* Step Block */}
                  <div className="step-block row">
                    {fields.map((field, index) => {
                      if (field.element === "button") return null; // Skip button rendering here

                      let fieldComponent;

                      switch (field.element) {
                        case "input":
                          fieldComponent = (
                            <div className="form-group col-md-6" key={`${field.element}~${index}`}>
                              <InputField
                                name={field.name}
                                grpmd={field.grpmd}
                                ref={field.ref}
                                type={field.type}
                                fieldClass={field.class}
                                placeholder={field.placeholder}
                                label={field.label}
                                labelClass={field.labelClass}
                                required={field.required}
                                key={`${field.element}~${index}`}
                                pattern={field.patternchk}
                                handlechange={handlechange}
                              />
                            </div>
                          );
                          break;

                        case "select":
                          fieldComponent = (
                            <div className="form-group col-md-6" key={`${field.element}~${index}`}>
                              <SelectField
                                name={field.name}
                                id={field.id}
                                grpmd={field.grpmd}
                                label={field.label}
                                ref={field.ref}
                                required={field.required}
                                fieldClass={field.class}
                                placeholder={field.placeholder}
                                values={field.values}
                                key={`${field.element}~${index}`}
                                handlechange={handlechange}
                                componentprops={props}
                              />
                            </div>
                          );
                          break;

                        case "textarea":
                          fieldComponent = (
                            <div className="form-group col-md-12" key={`${field.element}~${index}`}>
                              <TextAreaField
                                name={field.name}
                                grpmd={field.grpmd}
                                ref={field.ref}
                                rows={field.rows}
                                fieldClass={field.class}
                                label={field.label}
                                labelClass={field.labelClass}
                                placeholder={field.placeholder}
                                required={field.required}
                                key={`${field.element}~${index}`}
                                handlechange={handlechange}
                              />
                            </div>
                          );
                          break;

                        // case "checkbox":
                        //   fieldComponent = (
                        //     <div className="form-group col-md-6" key={`${field.element}~${index}`}>
                        //       <CheckboxField
                        //         name={field.name}
                        //         ref={field.ref}
                        //         value={field.value}
                        //         required={field.required}
                        //         handlechange={handlechange}
                        //       />
                        //       <label htmlFor={field.name}>{field.label}</label>
                        //     </div>
                        //   );
                        //   break;

                        // case "radio":
                        //   fieldComponent = (
                        //     <div className="form-group col-md-6" key={`${field.element}~${index}`}>
                        //       <RadioField
                        //         name={field.name}
                        //         ref={field.ref}
                        //         value={field.value}
                        //         checked={field.checked}
                        //         required={field.required}
                        //         handlechange={handlechange}
                        //         lastchild={field.lastchild}
                        //       />
                        //       <label htmlFor={field.name}>{field.label}</label>
                        //     </div>
                        //   );
                        //   break;

                        case "html":
                          fieldComponent = (
                            <div className="form-group col-md-12" key={`${field.element}~${index}`}>
                              <HtmlBox
                                text={field.text}
                                key={`${field.element}~${index}`}
                              />
                            </div>
                          );
                          break;

                        case "captcha":
                          fieldComponent = (
                            //<div className="form-group col-md-12" key={`${field.element}~${index}`}>
                              <ReCaptchaBox
                                fieldClass={field.class}
                                captRef={field.captchaRef}
                                key={`${field.element}~${index}`}
                                handleonVerify={handleonVerify}
                              />
                            //</div>
                          );
                          break;

                        default:
                          return null;
                      }

                      return fieldComponent;
                    })}
                  </div>

                  {/* Button Block */}
                  <div className="row">
                    {fields.map((field, index) => {
                      if (field.element === "button") {
                        return (
                          <div className="col-md-12 btn-contact" key={`${field.element}~${index}`}>
                            <ButtonField
                              name={field.name}
                              fieldClass={field.class}
                              type={field.type}
                              value={field.value}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>

                  <p className="form-title">By clicking Submit, you agree to our Terms & Conditions and Privacy Policy.</p>
                </Form>
              </div>
            </Col>

            <Col>
              <div>
                <div className="contact-info-static">
                  <p><strong>Phone:</strong> <br />{phone}</p>
                  <hr />
                  <p><strong>WhatsApp:</strong> <br />{whatsapp}</p>
                  <hr />
                  <p><strong>Email:</strong> <br /><a href="mailto:${email_address}">{email_address}</a></p>
                  <hr />
                  <p><strong>Office Address:</strong> <br />
                    {HTMLReactParser(formattedAddress)}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

      </div>
    </div>
  );




}


const StaticContactForm = (props) => (
  <StaticContactView subject={props} />
)

export default StaticContactForm
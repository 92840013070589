import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react";
import HTMLReactParser from 'html-react-parser';


import PlayVideo from "../../Play/custom-video";
import { Button, Container, Form } from 'react-bootstrap';
// Images
import BannerLarge from "../../../images/home/video-banner-medium.jpg"
import BannerMedium from "../../../images/home/video-banner-medium.jpg"
import Google from "../../../images/google-reviews.png"
import BannerSmall from "../../../images/home/video-banner-medium.jpg"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import axios from "axios";
import { GetURL } from "../../common/site/functions";
import { PhoneIconwhite, EnvelopeIconWhite, WhatsAppIconGreen } from "../../Icons/Icons";
import { useLocation } from "@reach/router"
import "./OfficeDetailsBanner.scss"
import "./Banner.scss"
import "../../../.././src/scss/custom.scss"
import ModalTiny from "react-bootstrap/Modal"
import Loadable from "@loadable/component"
import { useMediaQuery } from 'react-responsive'
const OfficeBookAView = Loadable(() => import("../../forms/office-details-form"))
const ValuationFormPage = Loadable(() => import("../../forms/valuation-form"))

const OfficeDetailsBanner = (props) => {
  console.log(props,"props1")
  const location = useLocation();
  let [isPlay, setPlay] = React.useState(false);
  let [reviewCount, setReviewCount] = React.useState(0);
  let [averageRating, setAverageRating] = React.useState(0);
  let url_1 = '';
  let url_2 = ''
  if (props.cta_1_link) {
    url_1 = GetURL(props.cta_1_link.id)
  }
  if (props.cta_2_link) {
    url_2 = GetURL(props.cta_2_link.id)
  }
  var location_link = process.env.GATSBY_SITE_URL + location.pathname;
  console.log(props?.page?.Phone,"phone")
  const number = props?.page?.Phone || '+971 50 882 6124';
  const WhatsappNumber = props?.page?.Whatsapp || '+971 50 882 6124'
  var whatsapp_link = `https://wa.me/${WhatsappNumber}?text=Hello!%20I%20want%20to%20know%20more%20about%20${location_link}`

  const [modalBookViewformOpen, setBookViewingformOpen] = React.useState(false);
  const openBookViewformModal = () => {
    setBookViewingformOpen(true);
  }
  const closeBookViewformModal = () => {
    setBookViewingformOpen(false);
  }

  const [modalValuationformOpen, setValuationformOpen] = React.useState(false);
  const openValuationformModal = () => {
    setValuationformOpen(true);
  }
  const closeValuationformModal = () => {
    setValuationformOpen(false);
  }

  const image_url = props.image.url;

  let processedImages = JSON.stringify({});
  if (props.page?.imagetransforms?.Banner_Image_Transforms) {
    processedImages = props.page.imagetransforms.Banner_Image_Transforms;
  }
  const wordToNumber = { 'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5 };
  const strapiconfig = {
    headers: {
      Authorization:
        `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }

  const getitems = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig)
      setReviewCount(data.length);
      let sumOfScores = 0;
      data.map((review) => {
        const reviewStringValue = review.starRating;
        sumOfScores += wordToNumber[reviewStringValue];
      })
      setAverageRating(Math.round(sumOfScores / data.length * 10) / 10);
    } catch (error) {
      // cache it if fail/error;

    }
  }
  getitems(`${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`);

  return (
    <React.Fragment>
              <section>

        <div className={`main-banner`} id="main-banner">

        <div className="main-banner-embed background-shadow d-xl-flex align-items-xl-stretch contact-office-details">
          <ImageTransform imagesources={image_url} renderer="srcSet" imagename="area-guides.Banner_Image.details" attr={{ alt: `${props.image.alternativeText} - Dacha`, class: '' }} imagetransformresult={processedImages} id={props.id} />

          {props.video &&
            <div className="banner-btn">
              <PlayVideo url={props.video}
              />
            </div>
          }
        </div>
        <div className="main-banner-content office-details">
        <Container>
            <div className="office-text">
              {props.content &&
                <div animateIn='fadeInUp' animateOnce>
                  <h1>{props.page.OfficeName}</h1>
                  {HTMLReactParser(props.content.replace(/\n/g, '<br />'))}
                  <div className="office-contact-info">
                     
                      <Link href={`tel:${number}`} className="icon-text-link">
                        <span><PhoneIconwhite className="icon-contact-info" /></span>
                        <span className="icon-contact-text">{number}</span>
                      </Link>
                    

                    <Link href={`mailto:${props?.page?.Email}`} className="icon-text-link">
                      <span><EnvelopeIconWhite className="icon-contact-info" /></span>
                      <span className="icon-contact-text">{props?.page?.Email}</span>
                    </Link>

                    {whatsapp_link ? (
                      <Link href={whatsapp_link} className="icon-text-link" target="_blank">
                        <span><WhatsAppIconGreen className="icon-contact-info" /></span>
                        <span className="icon-contact-text">WhatsApp</span>
                      </Link>
                    ) : (
                      <Link href={whatsapp_link} className="icon-text-link" target="_blank">
                        <span><WhatsAppIconGreen className="icon-contact-info" /></span>
                        <span className="icon-contact-text">WhatsApp</span>
                      </Link>
                    )}
                  </div>
                </div>
              }
              {(props.cta_1 || props.cta_2) &&
                <div className="banner-btns">
                  {props.cta_1 &&
                    <button className="btn btn-primary contact-book-btn" onClick={openBookViewformModal}>{props.cta_1}</button>
                  }
                  {/* {props.cta_1 && props.search === "Valuation_Form" &&
                    <button className="btn btn-primary contact-book-btn" onClick={openValuationformModal}>{props.cta_1}</button>
                  }
                  {props.cta_1 && props.search != "Book_Appointment" && props.search != "Valuation_Form" &&
                    <Link className="btn btn-primary" to={`/${url_1}`}>{props.cta_1}</Link>

                  } */}
                  {props.cta_2 &&
                    <Link className="btn btn-outline btn-link" to={`/${url_2}`}>{props.cta_2}</Link>
                  }
                </div>
              }

              {props.info &&
                <div className="connect">
                  <span className="connect-us">
                    <a className="call-us">
                      <i className="icon-call">
                      </i>
                    </a>
                  </span>
                  <span className="tel">
                    {HTMLReactParser(props.info.replace(/\n/g, '<br />'))}
                  </span>
                </div>
              }
              {props.rating === true &&
                <div className="google-rating">
                  <div className="img">
                    <img loading="lazy" src={Google} alt="google-review - Dacha" />
                  </div>
                  <div className="rating-content">
                    <p className="rating">{averageRating}/5 Rating from {reviewCount} Reviews</p>
                    {props.pagename != "Our Client Reviews" &&
                      <Link className="more" to="/about-dacha/customer-testimonials">View more reviews</Link>
                    }

                  </div>
                </div>
              }
            </div>
            </Container>
        </div>

      </div>
      </section>
      <ModalTiny size="lg" show={modalBookViewformOpen} onHide={closeBookViewformModal} className="book-a-viewing-modal office-details-form-model">
        <ModalTiny.Header className="contact-close-btn ">
          {/* //<span class="close-menu" onClick={closeBookViewformModal}><i class="close-icon"></i></span> */}
          <ModalTiny.Title className="w-100">
            <span  onClick={closeBookViewformModal}>
              <i className="close-icon-office"></i>
            </span>
            <h2 className="text-left form-title-office-head">Get in touch</h2>
            <p className="text-left form-title-office-para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam nec risus nec risus convallis aliquet lipsum.</p> 
          </ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body className="event-section">
          <OfficeBookAView
            property_img={``}
            property_id={''}
            page_url={null}
            property_title={''}
            negotiator={''} /></ModalTiny.Body>

      </ModalTiny>
      <ModalTiny size="lg" show={modalValuationformOpen} onHide={closeValuationformModal} className="book-a-viewing-modal share-to-friend-modal">
        <ModalTiny.Header className="contact-close-btn ">
          <span class="close-menu" onClick={closeValuationformModal}><i class="close-icon"></i></span>
          <ModalTiny.Title className="w-100"><h2 className="text-center mb-0">Property Valuation</h2></ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body className="event-section">
          <ValuationFormPage /></ModalTiny.Body>

      </ModalTiny>       
    </React.Fragment>
  )
}

export default OfficeDetailsBanner